<template>
<div class='user-info'>
    <!-- 详情修改 -->
    <div class="info-header">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list/info/myhome' }">我的家</el-breadcrumb-item>
      <el-breadcrumb-item >详情</el-breadcrumb-item>
    </el-breadcrumb>
  </div>

<!-- 家的名称 -->
  <div class="forbidden-input-module" style="padding-left:12px; padding-right:12px;">
        <i class="forbidden-input-i">家的名称:</i>
        <el-input
          placeholder="请输入内容"
          v-model="remark"
          clearable>
</el-input>

</div>
 
 <!-- 描述 -->
  <div class="forbidden-input-module-remark" style="padding-left:12px; padding-right:12px;">
    <i class="forbidden-input-i">描述:</i>
    <el-input
  placeholder="请输入内容"
  v-model="remark"
  clearable>
</el-input>

</div>

<!-- 壁纸 -->
 <div class="forbidden-input-module" style="padding-left:12px; padding-right:12px;">
    <i class="forbidden-input-i">壁纸:</i>
  <el-select v-model="value" placeholder="请选择" class="role">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
  <h4 class="family">我的家</h4>
  </div>
  
  <!-- 我的家 -->
  <div>
   <el-table :data="tableData" v-loading.body="listLoading" @selection-change="selsChange" class="list-table" >
        <el-table-column type="selection" width="35">
        </el-table-column>
        <el-table-column prop="ruleName" :show-overflow-tooltip="true" label="序号">
        </el-table-column>
      
     
        <el-table-column prop="remarks" min-width="95" :show-overflow-tooltip="true" label="用户名称">
        </el-table-column>

       
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <!-- <el-button  @click="handleEdit(scope.$index, scope.row)" :disabled='!editPermission' size="small">修改
            </el-button> -->
            <div class="delete-button" >
            <el-button :disabled='!editPermission' type="danger" size="small" @click="handleDel(scope.$index, scope.row)"
                       class="m-l-xs ">删除
            </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
  </div>


  <h3 class="family my-room">我的房间</h3>
  <div>
   <el-table :data="tableData" v-loading.body="listLoading" @selection-change="selsChange" class="list-table" >
        <el-table-column type="selection" width="35">
        </el-table-column>
        <el-table-column prop="ruleName" :show-overflow-tooltip="true" label="序号">
        </el-table-column>
        <el-table-column prop="calculation" min-width="95" :show-overflow-tooltip="true" label="房间名称">
        </el-table-column>
      
        <el-table-column prop="remarks" min-width="95" :show-overflow-tooltip="true" label="描述">
        </el-table-column>

      
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope" class="modification-buttom">
            <!-- <div class="modification-buttom"> -->
            <el-button  @click="handleEdit(scope.$index, scope.row)" :disabled='!editPermission' size="small">修改
            </el-button>
            <el-button :disabled='!editPermission' type="danger" size="small" @click="handleDel(scope.$index, scope.row)"
                       class="m-l-xs">删除
            </el-button>
            <!-- </div> -->
          </template>
        </el-table-column>
      </el-table>
  </div>
 <!--工具条-->
      <!-- <el-col :span="24" class="bottom-tool-container"> -->
        <!-- <router-link to="/device/detail"> -->
          <!-- <el-button @click="dialogShow=true"  type="primary">新增</el-button> -->
        <!-- </router-link> -->
        <!-- <el-button type="danger" :disabled="!this.sels.length>0" @click="batchRemove">批量删除</el-button>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page+1"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE*2, PAGE_SIZE*3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total" background 
          class="pull-right">
        </el-pagination>
      </el-col> -->




<el-dialog :title="isEdit?'修改':'新增滤芯计算规则'" :close-on-click-modal="false" size="small" :visible.sync="dialogShow">
          <el-form ref="formContent" :model="surplusInfo" label-width="80px" :rules="rules">
            <el-form-item label="滤芯类型" prop="code">
              <el-select v-model="surplusInfo.code" style="width:100%">
                <el-option v-for="(item, index) in surplusTypeList" :key="item"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="规则命名:" prop="ruleName">
              <el-input v-model="surplusInfo.ruleName"  placeholder="请输入规则名称"></el-input>
            </el-form-item>
            <el-form-item label="计算方式:">
              <el-input v-model="surplusInfo.calculation"  placeholder="请输入计算方式"></el-input>
            </el-form-item>
            <el-form-item label="低效滤网:" prop='lowRule'>
              <el-select v-model="surplusInfo.lowRule" style="width:100%">
                <el-option v-for="(item, index) in [{value: 'true',label: '是'},{value: 'false',label: '否'}]" :key="index" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="最大值:" prop="maxValue">
              <el-input v-model="surplusInfo.maxValue"  placeholder="请输入滤芯寿命最大值"></el-input>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input type="textarea" :rows="5" :maxlength="200" v-model="surplusInfo.remarks"
                        placeholder="请输入设备描述"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer" style="margin-top: -20px">
            <el-button @click="dialogShow = false">取 消</el-button>
            <el-button type="primary" @click="submitDvc">确 定</el-button>
          </div>
    </el-dialog>

   <el-button class="submit" type="primary" @click="submitForm('numberValidateForm')">保存</el-button>
    </div>
    
  </div>

</template>
  
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
 import { querySurplusRules, createSurplusRules, updateSurplusRules, delSurplusRules} from '../../services/device';
  import { updateDefaultDevice } from "../../services/ucenter";
  import { getAllCustomers } from '../../services/customer';
  import {Notification} from 'element-ui';
  import {PAGE_SIZE} from '../../config';
  import DeviceDetail from '../device/detail.vue';
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment';
  import {valiInput,valiSelect,valiNoEmptyNumber} from "../../utils/validator/element";


 export default{
    data(){
      return {
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        realname: '',
        phone:'',
        phonetwo:'',
        affiliatedunit:'',
        remark:'',

        pager: {
          size: PAGE_SIZE,
          page: 1,

        },
        total: 1,
        choosedRowIndex: -1,//当前选中的列，主要是在删除和修改的时候用
        tableData: [],
        filters: {
          key: '',
          code: '1',
        },
        //下面是对话框中的信息
        surplusInfo: {
          code: '',
          ruleName: '',
          calculation: '',
          remarks: '',
          maxValue: '',
          lowRule: '',
        },
        listLoading: false,
        sels: [],//列表选中列
        dialogShow: false,
        surplusTypeList: [
          {code: '1', name: '一层滤芯寿命'},
          {code: '2', name: '二层滤芯寿命'},
          {code: '3', name: '三层滤芯寿命'},
          {code: '4', name: '四层滤芯寿命'}
        ],
        rules: {
          code: {
            validator: valiSelect, trigger: 'change'
          },
          lowRule: {
            validator: valiSelect, trigger: 'change'
          },
          ruleName: {
            validator: valiInput, trigger: 'blur' 
          },
          maxValue: {
            validator: valiNoEmptyNumber, trigger: 'blur' 
          },
        }
      }
    },
    components: {
      'device-detail': DeviceDetail
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    watch: {
      dialogShow(newval) {
        if (!newval) {
          this.surplusInfo = {
            code: '',
            ruleName: '',
            calculation: '',
            remarks: '',
            maxValue: '',
            lowRule: ''
          };
        }
      }
    },
    methods: {
      submitDvc() {
        this.$refs.formContent.validate((valid) => {
          if (valid) {
            this.dialogShow = false;
            if(this.surplusInfo.lowRule === 'true') {
              this.surplusInfo.lowRule = true;
            }
            if(this.surplusInfo.lowRule === 'false') {
              this.surplusInfo.lowRule = false;
            }

            //console.log(this.surplusInfo)
            this.updateDvcType(this.surplusInfo);
          }
        })
      },
      /**
       * 更新滤芯规则
       */
      // async updateDvcType(obj) {
      //   let res = null;
      //   if (this.isEdit) {
      //     res = await updateSurplusRules(obj);
      //   } else {
      //     res = await createSurplusRules(obj);
      //   }
      //   if (res && res.errorCode === 0) {
      //     this.querySurplusRules();
      //     Notification.success({
      //       title: '成功',
      //       message: this.isEdit?'修改成功':'新增成功'
      //     });
      //   }
      //   this.isEdit = false;
      // },

      /**
       * 设备编辑权限判断
       */
      checkEditPermission() {
          let permissions = this.user && this.user.permissions;
          this.editPermission = permissions.some((item) => {
            return item === 'Device:update'
          })
      },
      formatTime(row){
        return row && row.updateTime ? moment(row.updateTime).format('YYYY-MM-DD HH:mm:ss') : '';
      },

      /**
       * 单条数据的删除
       */
      handleDel: function (index, row) {
        this.$confirm('确认删除该记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          //NProgress.start();
          delSurplusRules(row.id).then((res) => {
            this.listLoading = false;
            if (res.errorCode === 0) {
              Notification.success({
                title: '成功',
                message: '删除成功'
              });
              this.querySurplusRules();
            }
          });
        }).catch(() => {
        });
      },
      /**
       * 跳转到编辑
       */
      // handleEdit: function (index, row) {
      //   this.dialogShow = true;
      //   // console.log(row)
      //   this.isEdit = true;
      //   this.choosedRowIndex = index;
      //   const choosedRules = Object.assign({}, row);
      //   this.surplusInfo = {
      //     id: choosedRules.id,
      //     ruleName: choosedRules.ruleName,
      //     calculation: choosedRules.calculation,
      //     remarks: choosedRules.remarks,
      //     maxValue: choosedRules.maxValue,
      //     lowRule: choosedRules.lowRule,
      //     code: choosedRules.code
      //   }
      //   if(this.surplusInfo.lowRule === true) {
      //     this.surplusInfo.lowRule = 'true';
      //   }
      //   if(this.surplusInfo.lowRule === false) {
      //     this.surplusInfo.lowRule = 'false';
      //   }
      // },


      /**
       * 定义序号的排序
       */
      sortDeviceByTime({column, prop, order}){
        if (prop === 'index') {
          if (order === 'ascending') {
            this.pager.sort = 'updateTime,asc';
          } else {
            this.pager.sort = 'updateTime,desc';
          }
          this.queryDevices();
        }
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function (sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove(){
        const ids = this.sels.map(item => item.id).toString();
        console.log(ids)
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          //NProgress.start();
          delSurplusRules(ids).then((res) => {
            this.listLoading = false;
            //NProgress.done();
            if (res.errorCode === 0) {
              Notification.success({
                title: '成功',
                message: '批量删除成功'
              });
              this.querySurplusRules();
            }
          });
        }).catch(() => {
        });
      },
      /**
       * 根据设备名筛选
       */
      getDeviceByName(){
        this.querySurplusRules();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page){
//        this.pager.page = page; 
//        this.querySurplusRules();
        if (this.loaded) {
          this.pager.page = page; 
          // window.router.replace({path: 'kind', query: {page: page}})
          this.querySurplusRules();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val){
        this.pager.size = val;
        this.pager.page = 1;
        this.querySurplusRules();
      },

      /**
       * 获取产品型号列表
       */
      async querySurplusRules(){
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters)
        // if (Object.prototype.toString.call(searchParam.isProxy) != '[object Boolean]') {
        //   delete searchParam.isProxy;
        // }
        const responseData = await querySurplusRules(searchParam, this.pager);
        if (responseData.errorCode === 0) {
          this.tableData = responseData && responseData.data && responseData.data.datas || [];
          this.total = responseData.data.totalCount;
          // alert(1)
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 处理禁用还是启用
       * @param index 当先列的下标
       * @param row   当前列的数据对象
       * @param disabled Boolean true调用禁用  false调用启用
       */
      async handleDisabled(index, row, disabled){
        let disableData = null;
        if (disabled) {
          disableData = await disabledDevice(row.id);
        } else {
          disableData = await enabledDevice(row.id);
        }
        if (disableData.errorCode == 0 && disableData.data == 'done') {
          row.locked = disabled;
          Notification.success({
            title: '成功',
            message: disabled ? '禁用成功' : '启用成功'
          });

        }
      }
    },
    created(){
      this.querySurplusRules();
    }
  }
</script>
<style  lang="less" scoped>
 @import "../../assets/css/reset.css";
.user-info{
  padding: 14px 10px 0 14px;
  position: absolute;
    top: 38px;
    min-height: 91%;
    right: 14px;
    left: 14px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;

.info-header {
  margin-bottom: 14px !important;
    // margin: 0 auto;
    // width: 92%;
    // .el-form-item {
    //   
    // }
  }
}
.forbidden-input-module{
  width: 34%;
  float: left;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.forbidden-input-module-remark{
  width: 34%;
  float: left;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-left: 10%;
}
.forbidden-input{

    position: absolute;
    top: 38px;
    min-height: 91%;
    right: 14px;
    left: 14px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
}
.family{
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
  Color: #4E4E4E;
}

.el-input__inner {
    width: 100%;
    height: 35px;
}

.el-input__inner {

    -webkit-appearance: none;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    display: inline-block;
    font-size: inherit;
    line-height: 35px;
    outline: 0;
    color: #C0C4CC;
    background-color: #F5F7FA;
    padding-right: 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    
}
.forbidden-input-i{
display: block;
text-align: left;
font-size: 14px;
color: #606266;
line-height: 14px;
margin-bottom: 16px;

}

.role{
  width: 100%;
  margin-bottom: 30px;
}
.el-input__suffix {
    right: 5px;
    transition: all .3s;
}
.el-input__suffix {
    position: absolute;
    top: 0;
    -webkit-transition: all .3s;
    height: 100%;
    color: #C0C4CC;
    text-align: center;
}
.el-input__suffix{
  pointer-events: none;
}
.el-select .el-input .el-select__caret{
  color: #C0C4CC;
font-size: 14px;
transition: transform .3s,-webkit-transform .3s;
transform: rotateZ(180deg);
cursor: pointer;
}
.el-input__icon{
  height: 100%;
width: 25px;
text-align: center;
line-height: 40px;
}
.el-input__suffix-inner {
    pointer-events: all;
}
[class*=" el-icon-"]{
  font-family: element-icons !important;
  font-style: normal;
font-weight: 400;
font-variant: normal;
text-transform: none;
vertical-align: baseline;
display: inline-block;
}
// .list-table{
//   font-size: 13px;
//   font-weight: 600;
//   color:  #666666;
// }
// .font-format{
//   font-size: 13px;
//   font-weight: 600;
//   color:  #666666;
// }
.submit{
  float: right;
  margin-top: 50px;
  margin-right: 40px;
  width: 100px;
}

.my-room{
  margin-top: 100px;
  margin-bottom: 20px;
}
.delete-button{

  margin-left: 45px;
}
.modification-buttom{
  margin-right: 70px;
}
.el-table td, .el-table th{
  text-align: center;
}
</style>