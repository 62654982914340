/**
 * Created by xinsw on 16/9/9.
 *
 * 客户service封装
 *
 */
import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 获取所有的客户列表
 * @returns {Promise.<*>}
 */
export async function getAllCustomers(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("customer", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据客户名搜索
 * @returns {Promise.<*>}
 */
export async function searchCustomerByKey(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("customer", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 获取某一品牌商的经销商
 * @returns {Promise.<*>}
 */
export async function queryProxy() {
  try {
    return await API.get("customer/proxy");
  } catch (error) {
    return error;
  }
}

/**
 * 品牌商ID 获取经销商
 * @returns {Promise.<*>}
 */
export async function getProxyByCustId(params, pager = { size: 15, page: 0 }) {
  try {
    return await API.get("customer/byProxy", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有品牌商
 * @returns {Promise.<*>}
 */
export async function queryBrander(params) {
  try {
    return await API.get("customer/isProxy", params);
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有品牌商,(新增公司介绍页面时用，已新建的品牌商不会出现)
 * @returns {Promise.<*>}
 */
export async function queryBranderExist(params) {
  try {
    return await API.get("company/byCustomerExist", params);
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个客户
 * @param customer Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function createCustomer(customer) {
  try {
    return await API.post("customer", customer);
  } catch (error) {
    return error;
  }
}

/**
 * 修改一个客户
 * @param customer Object {name:'',code:'',desc:''}
 * @returns {Promise.<*>}
 */
export async function updateCustomerInfo(customer) {
  try {
    return await API.post("customer/update", customer);
  } catch (error) {
    return error;
  }
}

/**
 * 删除客户
 * @param ids String
 * @returns {Promise.<*>}
 */
export async function removeCustomer(ids) {
  try {
    return await API.del("customer/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取客户的信息
 * @param id String 客户ID
 * @returns {Promise.<*>}
 */
export async function getCustomerById(id) {
  try {
    return await API.get("customer/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 禁止客户
 * @param id String 客户ID
 * @returns {Promise.<*>}
 */
export async function disabledCustomer(id) {
  try {
    return await API.post("customer/" + id + "/locked");
  } catch (error) {
    return error;
  }
}

/**
 * 启用客户
 * @param id String 客户ID
 * @returns {Promise.<*>}
 */
export async function enabledCustomer(id) {
  try {
    return await API.post("customer/" + id + "/unlocked");
  } catch (error) {
    return error;
  }
}

/**
 * 根据key搜索公众号
 * @returns {Promise.<*>}
 */
export async function searchPubNumberByKey(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("publicNumber", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据id搜索公众号
 * @returns {Promise.<*>}
 */
export async function searchPubNumberById(id) {
  try {
    return await API.get("publicNumber/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 新建公众号
 * @returns {Promise.<*>}
 */
export async function createPubNumber(params) {
  try {
    return await API.post("publicNumber", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 更新公众号
 * @returns {Promise.<*>}
 */
export async function updatePubNumberInfo(params) {
  try {
    return await API.post("publicNumber/update", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 删除公众号
 * @returns {Promise.<*>}
 */
export async function delPubNumber(ids) {
  try {
    return await API.del("publicNumber/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 根据key搜索公司
 * @returns {Promise.<*>}
 */
export async function searchCompanysByKey(
  params,
  pager = { siez: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("company", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 根据ID搜索公司
 * @returns {Promise.<*>}
 */
export async function getcompanyInfoById(id) {
  try {
    return await API.get("company/" + id);
  } catch (error) {
    return error;
  }
}

/**
 * 创建公司
 * @returns {Promise.<*>}
 */
export async function createCompany(params) {
  try {
    return await API.post("company", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 更新公司信息
 * @returns {Promise.<*>}
 */
export async function updateCompanyInfo(params) {
  try {
    return await API.post("company/update", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 删除公司
 * @returns {Promise.<*>}
 */
export async function delCompany(ids) {
  try {
    return await API.del("company/" + ids);
  } catch (error) {
    return error;
  }
}

export async function getAuthorModel() {
  try {
    return await API.get("deviceAuthorize/model");
  } catch (error) {
    return error;
  }
}
